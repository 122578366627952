<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix modal-heading">
      <span>AgilityAds Integration Settings</span>
      <el-button type="text" @click="$modal.hide('tracker-agility-form')">
        <font-awesome-icon icon="window-close" fixed-width></font-awesome-icon>
      </el-button>
    </div>
    <el-form
      @submit.native.prevent="submit"
      :label-position="$root.windowWidth > 767 ? 'right' : 'top'"
      label-width="100px"
    >
    <div v-for="(service, key) in services" :key="key">
      <el-checkbox v-model="service.selected" @change="getAdvertisers()" style="margin-bottom: 0.5rem">{{
          service.name
        }}</el-checkbox>

      <div v-if="service.selected">
        <el-form-item
        :error="
          form.errors.first('services.' + key + '.company')
            ? form.errors.first('services.' + key + '.company')
            : null
        "
        label="Advertiser"
        required
      >
        <modal-select>
          <el-select
            v-model="service.company"
            :style="{ width: '100%' }"
            @focus="form.errors.clear('services.' + key + '.company')"
          >
            <el-option
              v-for="advertiser in service.advertisers"
              :key="advertiser.advertiser_id"
              :label="advertiser.advertiser_name"
              :value="advertiser.advertiser_id"
            ></el-option>
          </el-select>
        </modal-select>
      </el-form-item>
      </div>
    </div>

      <el-button
        type="primary"
        plain
        medium
        native-type="submit"
        :style="{ width: '100%' }"
        v-loading="loading"
        :disabled="loading"
      >
        Submit
      </el-button>
    </el-form>
  </el-card>
</template>

<script>
import Form from "form-backend-validation"
import { mapActions, mapGetters } from "vuex"

export default {
  name: "TrackerAgilityAdsForm",
  props: {
    existing: {
      required: true,
    },
  },
  data() {
    return {
      form: new Form(),
      servicesData: {},
      selected: null,
      placement: "",
      loading: false,
      services: {
        agilityDisplay: {
          name: "Agility Display",
          company: null,
          selected: false,
          advertisers: [],
        },
        agilityTv: {
          name: "Agility TV",
          company: null,
          selected: false,
          advertisers: [],
        },
      }
    }
  },
  computed: {
    ...mapGetters({
      company: "companies/current",
    }),
    action() {
      return "tracker/" + this.company.slug + "/agilityads/update"
    },
  },
  methods: {
    ...mapActions({
      updateCompany: "tracker/updateAdmin",
    }),

    submit() {
      this.loading = true

      let formData = {
        services: {},
      }

      if (this.services.agilityDisplay.selected) {
        formData.services.agilityDisplay = this.services.agilityDisplay.company
      }
      if (this.services.agilityTv.selected) {
        formData.services.agilityTv = this.services.agilityTv.company
      }

      this.axios
        .post(this.action, formData)
        .then((response) => {
          this.updateCompany(response.data.data)
          this.$message.success(response.data.message)
          this.$modal.hide("tracker-agility-form")
          this.loading = false
        })
        .catch((resp) => {
          this.loading = false
          if (resp.response && resp.response.status === 422) {
            this.form.onFail(resp)
          } else {
            this.$root.globalRequestError(resp)
          }
        })
    },
  },
  mounted() {
    this.loading = true

    this.axios
      .get("/agilityads/advertisers/display")
      .then(({ data }) => {
        this.services.agilityDisplay.advertisers = data.data
      })
      .finally(() => (this.loading = false))

    this.axios
      .get("/agilityads/advertisers/tv")
      .then(({ data }) => {
        this.services.agilityTv.advertisers = data.data
      })
      .finally(() => (this.loading = false))

    if (this.existing !== null) {
      this.servicesData = JSON.parse(this.existing);

      this.$_.forEach(this.servicesData, (item, key) => {
        this.services[key].company = item
        this.services[key].selected = true
      })
    }
  },
}
</script>

<style scoped lang="scss">
</style>
